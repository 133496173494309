import React, { Fragment, useEffect, useState } from "react";
import { Badge, NavDropdown } from "react-bootstrap";
import { FiBell } from "react-icons/fi";
import api from "../services/api";
import { Link } from "react-router-dom";

export default function Notify() {
  const [kendaraan, setKendaraan] = useState({ total: 0, data: [] });

  const fetchKendaraan = async () => {
    const { data } = await api.get(`/kendaraan/warning`);
    setKendaraan(data);
  };

  useEffect(() => {
    fetchKendaraan();
  }, []);

  return (
    <NavDropdown
      title={
        <Fragment>
          <FiBell size={22} />
          {kendaraan.total > 0 && (
            <Badge bg="info" pill className="badge-up">
              {kendaraan.total}
            </Badge>
          )}
        </Fragment>
      }
      id="info-dropdown"
      className="no-caret border-bottom-item"
    >
      <NavDropdown.Header className="d-flex align-items-center justify-content-between">
        <div>Notifications</div>
        <Badge bg="info" pill>
          {kendaraan.total} new
        </Badge>
      </NavDropdown.Header>
      <NavDropdown.Divider />

      {kendaraan.data.map((item, i) => (
        <NavDropdown.Item
          key={i}
          className="d-flex flex-column"
          as={Link}
          to={`/main/kendaraan/edit/${item.id}`}
        >
          <div>{item.no_polisi}</div>
          <small className="text-muted">{`${item.merk} ${item.tipe}`}</small>
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );
}
