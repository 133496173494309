import axios from 'axios';
import React, { useEffect, useState } from 'react';
import api from '../services/api';
import { Button, Table } from 'react-bootstrap';
import { FiRefreshCcw } from 'react-icons/fi';
import { ImQrcode } from 'react-icons/im';

export default function CardWablas() {
  const sourceToken = axios.CancelToken.source();

  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({ status: true, data: {} });

  const fetchData = async () => {
    setLoading(true);

    const { data } = await api.get(`/wablas/device-info`, {
      cancelToken: sourceToken.token
    });

    setResponse(data);
    setLoading(false);
  }

  useEffect(() => {
    fetchData();

    return () => {
      sourceToken.cancel('Aborting current request.');
    }
  }, []);

  return (
    <div className="card mb-3">
      <div className="card-header text-bg-secondary d-flex align-items-center justify-content-between">
        <div>{loading ? "Loading..." : "Wablas Status"}</div>
        <div>
          <Button variant="outline-primary" className="border-0" size="sm" onClick={fetchData}>
            <FiRefreshCcw />
          </Button>
        </div>
      </div>
      <div className="card-body">
        <Table responsive borderless hover>
          <tbody>
            <tr>
              <th>Name</th>
              <th>:</th>
              <td>{response.data?.name}</td>
            </tr>
            <tr>
              <th>Sender</th>
              <th>:</th>
              <td>{response.data?.sender}</td>
            </tr>
            <tr>
              <th>Quota</th>
              <th>:</th>
              <td>{response.data?.quota}</td>
            </tr>
            <tr>
              <th>Expired Date</th>
              <th>:</th>
              <td>{response.data?.expired_date}</td>
            </tr>
            <tr>
              <th>Serial</th>
              <th>:</th>
              <td>{response.data?.serial}</td>
            </tr>
            <tr>
              <th>Status</th>
              <th>:</th>
              <td className={response.data?.status === 'disconnected' ? 'text-danger' : 'text-success'}>
                {response.data?.status}
              </td>
            </tr>
          </tbody>
        </Table>

        <div className="d-grid gap-2">
          <Button 
            variant="primary"
            size="lg"
            onClick={() => window.open(`https://solo.wablas.com/api/device/scan?token=${response.data?.token}`, '_blank')}
          >
            <ImQrcode size={23} className="mb-1 me-2" />
            <span>Scan QR Code</span>
          </Button>
        </div>
      </div>
    </div>
  )
}
