import React, { Fragment } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { NavDropdown, Image } from "react-bootstrap";
import { FiUser, FiSettings, FiLogOut, FiMenu } from "react-icons/fi";
import { useApp } from "./Provider";
import { PATH_PLACEHOLDER_USER, PATH_STORAGE } from "../services/api";
import Notify from "./Notify";

export default function Navbar() {
  const { toggleModeIcon } = useApp();

  return (
    <nav className="navbar fixed-top navbar-expand-md main-navbar">
      <div className="container-fluid">
        <div className="navbar navbar-expand w-100 px-3 d-flex d-md-none bg-white shadow-sm rounded">
          <div>
            <FiMenu
              className="pointer"
              size="1.3rem"
              onClick={toggleModeIcon}
            />
          </div>
          <div className="navbar-nav ms-auto align-items-center">
            <Notify />
            <AccountDropdownComponent />
          </div>
        </div>

        <div className="collapse navbar-collapse bg-white text-dark">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <Notify />
            <AccountDropdownComponent />
          </ul>
        </div>
      </div>
    </nav>
  );
}

const AccountDropdownComponent = () => {
  const { user, signout } = useApp();
  const navigate = useNavigate();

  return (
    <NavDropdown
      title={
        <Fragment>
          <div className="status">
            <div className="fw-bold">{user?.name}</div>
            <small className="text-muted">{user?.role?.name}</small>
          </div>

          <Image
            src={
              !!user.photo
                ? `${PATH_STORAGE}/users/${user.photo}`
                : PATH_PLACEHOLDER_USER
            }
            className="profile-picture"
            style={{ objectFit: "cover", objectPosition: "center" }}
          />

          <span className="position-absolute bg-success rounded-circle badge-online"></span>
        </Fragment>
      }
      className="account no-caret"
    >
      <NavDropdown.Item as={NavLink} to="/main/user">
        <FiUser className="icon" />
        <span>Profile</span>
      </NavDropdown.Item>
      <NavDropdown.Item as={NavLink} to="/main/setting">
        <FiSettings className="icon" />
        <span>Settings</span>
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item
        onClick={() => {
          signout(() => navigate("/login", { replace: true }));
        }}
      >
        <FiLogOut className="icon" />
        <span>Logout</span>
      </NavDropdown.Item>
    </NavDropdown>
  );
};
