import axios from "axios";
import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { FaCar } from "react-icons/fa";
import { VscRefresh } from "react-icons/vsc";
import { Button } from "react-bootstrap";

export default function StatsKendaraan() {
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);

  const fetchData = async () => {
    const sourceToken = axios.CancelToken.source();

    try {
      setLoading(true);
      const { data } = await api.get(`/kendaraan/stats`, {
        cancelToken: sourceToken.token,
      });
      setCount(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const sourceToken = axios.CancelToken.source();

    fetchData();

    return () => {
      sourceToken.cancel("Aborting current request.");
    };
  }, []);

  return (
    <div className="card text-bg-secondary mb-3">
      <div className="card-body">
        <div className="mb-3 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <FaCar className="me-2" />
            <span>{loading ? "Loading..." : "Kendaraan"}</span>
          </div>
          <div>
            <Button
              variant="outline-dark"
              className="border-0"
              size="sm"
              onClick={fetchData}
            >
              <VscRefresh />
            </Button>
          </div>
        </div>
        <div className="text-center">
          <p className="display-6 mb-0">{count}</p>
        </div>
      </div>
    </div>
  );
}
