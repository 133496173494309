import Axios from 'axios';
import { toast } from 'react-toastify';

let urls = {
  test: process.env.REACT_APP_API_DEV,
  development: process.env.REACT_APP_API_DEV,
  production: process.env.REACT_APP_API_PRO
}

export const BASE_URL = urls[process.env.NODE_ENV];
export const API_URL = `${BASE_URL}/api`;
export const PATH_STORAGE = `${BASE_URL}/storage`;
export const PATH_PLACEHOLDER_USER = `${BASE_URL}/placeholder/user.png`;
export const API_KEY = process.env.REACT_APP_API_KEY;

const api = Axios.create({
  baseURL: API_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

export default api;

api.interceptors.response.use(
  response => {
    const method = response.config['method'];
    if (method === 'post' || method === 'put' || method === 'delete') {
      toast.success(response.data?.message, {
        position: 'bottom-center'
      });
    }
    return response;
  },
  error => {
    const statusCode = error?.response?.status;
    if (statusCode === 401) {
      localStorage.removeItem(API_KEY);
      window.location.reload();
    }

    if (error.code !== 'ERR_CANCELED') {
      const message = error.response?.data?.message || 'Something went wrong!';
      toast.error(message, {
        position: 'bottom-center'
      });
    }

    return Promise.reject(error);
  }
);