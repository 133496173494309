import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyCPBivCPMPQ1qEU2KcXql37k6XFH3fBuG8",
  authDomain: "rax-cargo.firebaseapp.com",
  projectId: "rax-cargo",
  storageBucket: "rax-cargo.appspot.com",
  messagingSenderId: "155051751802",
  appId: "1:155051751802:web:9b619d1442f6e107733ec3",
  measurementId: "G-1TJEMX2888"
};


function requestPermission() {
  // console.log('Requesting permission...');
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      // console.log('Notification permission granted.');
      // Initialize Firebase
      const app = initializeApp(firebaseConfig);
      
      // Initialize Firebase Cloud Messaging and get a reference to the service
      const messaging = getMessaging(app);
      getToken(messaging, { vapidKey: 'BCd1J9pzwWq873qRR41xZmQK9wRdENjMNkL7DO8xNKVymfoAGvU3nCIAidSOgLKpCVZW8OwjAr30uzvvR_dCUcY' }).then((currentToken) => {
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          // console.log('currentToken :', currentToken);
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
    } else {
      console.log('Do not have permission!');
    }
  });
}

requestPermission();