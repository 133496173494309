import './messaging_init_in_sw';
import React, { lazy, Suspense } from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Layout from './components/Layout';
import Provider from './components/Provider';
import LoginPage from './pages/auth/Login';
import RegisterPage from './pages/auth/Register';
import NotFoundPage from './pages/Error404';
import DashboardPage from './pages/Dashboard';
const PageKantor = lazy(() => import('./pages/main/master/kantor/PageKantor'));
const FormKantor = lazy(() => import('./pages/main/master/kantor/FormKantor'));
const PageUser = lazy(() => import('./pages/main/master/users/PageUser'));
const FormUser = lazy(() => import('./pages/main/master/users/FormUser'));
const PageLayanan = lazy(() => import('./pages/main/master/layanan/PageLayanan'));
const FormLayanan = lazy(() => import('./pages/main/master/layanan/FormLayanan'));
const PageJenisBarang = lazy(() => import('./pages/main/master/barang/PageJenisBarang'));
const FormJenisBarang = lazy(() => import('./pages/main/master/barang/FormJenisBarang'));
const PageJenisPembayaran = lazy(() => import('./pages/main/master/jenis-pembayaran/PageJenisPembayaran'));
const FormJenisPembayaran = lazy(() => import('./pages/main/master/jenis-pembayaran/FormJenisPembayaran'));
const PageJenisKiriman = lazy(() => import('./pages/main/master/jenis-kiriman/PageJenisKiriman'));
const FormJenisKiriman = lazy(() => import('./pages/main/master/jenis-kiriman/FormJenisKiriman'));
const PageJenisLevel = lazy(() => import('./pages/main/master/jenis-level/PageJenisLevel'));
const FormJenisLevel = lazy(() => import('./pages/main/master/jenis-level/FormJenisLevel'));
const PageHarga = lazy(() => import('./pages/main/master/harga-pengiriman/PageHarga'));
const FormHarga = lazy(() => import('./pages/main/master/harga-pengiriman/FormHarga'));
const PagePickupOrder = lazy(() => import('./pages/main/pickup-order/PagePickupOrder'));
const ResiPage = lazy(() => import('./pages/main/resi/ResiPage'));
const ManifestPage = lazy(() => import('./pages/main/manifest/ManifestPage'));
const PembiayaanPage = lazy(() => import('./pages/main/pembiayaan/PembiayaanPage'));
const VendorPage = lazy(() => import('./pages/main/vendor/VendorPage'));
const VendorForm = lazy(() => import('./pages/main/vendor/VendorForm'));
const VendorDetail = lazy(() => import('./pages/main/vendor/VendorDetail'));
const KendaraanPage = lazy(() => import('./pages/main/kendaraan/KendaraanPage'));
const KendaraanForm = lazy(() => import('./pages/main/kendaraan/KendaraanForm'));
const LaporanRoute = lazy(() => import('./pages/main/laporan/LaporanRoute'));
const BroadcastPage = lazy(() => import('./pages/main/broadcast/BroadcastPage'));
const WebsiteRoute = lazy(() => import('./pages/main/website/WebsiteRoute'));
const ProfilePage = lazy(() => import('./pages/main/user/ProfilePage'));
const SettingPage = lazy(() => import('./pages/main/setting/SettingPage'));
const CustomerPage = lazy(() => import('./pages/main/master/customer/CustomerPage'));
const CustomerForm = lazy(() => import('./pages/main/master/customer/CustomerForm'));
const KotaPage = lazy(() => import('./pages/main/master/wilayah/KotaPage'));
const KecamatanPage = lazy(() => import('./pages/main/master/wilayah/KecamatanPage'));
const KelurahanPage = lazy(() => import('./pages/main/master/wilayah/KelurahanPage'));

export const MySwal = withReactContent(Swal);

export default function App() {
  return (
    <Provider>
      <Routes>
        <Route element={<Outlet />}>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/error-404" element={<NotFoundPage />} />
          <Route path="*" element={<Navigate to="/main/dashboard" />} />
        </Route>

        <Route path="/main" element={<Layout />}>
          <Route path="dashboard" element={<DashboardPage />} />
          <Route path="master" element={<Outlet />}>
            <Route path="wilayah" element={<Outlet />}>
              <Route path="kota" element={<Outlet />}>
                <Route index element={<Suspense fallback={false}><KotaPage /></Suspense>} />
              </Route>
              <Route path="kecamatan" element={<Outlet />}>
                <Route index element={<Suspense fallback={false}><KecamatanPage /></Suspense>} />
              </Route>
              <Route path="kelurahan" element={<Outlet />}>
                <Route index element={<Suspense fallback={false}><KelurahanPage /></Suspense>} />
              </Route>
            </Route>
            <Route path="kantor" element={<Outlet />}>
              <Route index element={<Suspense fallback={false}><PageKantor /></Suspense>} />
              <Route path="create" element={<Suspense fallback={false}><FormKantor /></Suspense>} />
              <Route path="edit/:kode" element={<Suspense fallback={false}><FormKantor /></Suspense>} />
            </Route>
            <Route path="users" element={<Outlet />}>
              <Route index element={<Suspense fallback={false}><PageUser /></Suspense>} />
              <Route path="create" element={<Suspense fallback={false}><FormUser /></Suspense>} />
              <Route path="edit/:id" element={<Suspense fallback={false}><FormUser /></Suspense>} />
            </Route>
            <Route path="pengirim" element={<Outlet />}>
              <Route index element={<Suspense fallback={false}><CustomerPage /></Suspense>} />
              <Route path="create" element={<Suspense fallback={false}><CustomerForm /></Suspense>} />
              <Route path="edit/:id" element={<Suspense fallback={false}><CustomerForm /></Suspense>} />
            </Route>
            <Route path="penerima" element={<Outlet />}>
              <Route index element={<Suspense fallback={false}><CustomerPage /></Suspense>} />
              <Route path="create" element={<Suspense fallback={false}><CustomerForm /></Suspense>} />
              <Route path="edit/:id" element={<Suspense fallback={false}><CustomerForm /></Suspense>} />
            </Route>
            <Route path="layanan" element={<Outlet />}>
              <Route index element={<Suspense fallback={false}><PageLayanan /></Suspense>} />
              <Route path="create" element={<Suspense fallback={false}><FormLayanan /></Suspense>} />
              <Route path="edit/:id" element={<Suspense fallback={false}><FormLayanan /></Suspense>} />
            </Route>
            <Route path="jenis-barang" element={<Outlet />}>
              <Route index element={<Suspense fallback={false}><PageJenisBarang /></Suspense>} />
              <Route path="create" element={<Suspense fallback={false}><FormJenisBarang /></Suspense>} />
              <Route path="edit/:id" element={<Suspense fallback={false}><FormJenisBarang /></Suspense>} />
            </Route>
            <Route path="jenis-pembayaran" element={<Outlet />}>
              <Route index element={<Suspense fallback={false}><PageJenisPembayaran /></Suspense>} />
              <Route path="create" element={<Suspense fallback={false}><FormJenisPembayaran /></Suspense>} />
              <Route path="edit/:id" element={<Suspense fallback={false}><FormJenisPembayaran /></Suspense>} />
            </Route>
            <Route path="jenis-kiriman" element={<Outlet />}>
              <Route index element={<Suspense fallback={false}><PageJenisKiriman /></Suspense>} />
              <Route path="create" element={<Suspense fallback={false}><FormJenisKiriman /></Suspense>} />
              <Route path="edit/:id" element={<Suspense fallback={false}><FormJenisKiriman /></Suspense>} />
            </Route>
            <Route path="jenis-level" element={<Outlet />}>
              <Route index element={<Suspense fallback={false}><PageJenisLevel /></Suspense>} />
              <Route path="create" element={<Suspense fallback={false}><FormJenisLevel /></Suspense>} />
              <Route path="edit/:id" element={<Suspense fallback={false}><FormJenisLevel /></Suspense>} />
            </Route>
            <Route path="harga-pengiriman" element={<Outlet />}>
              <Route index element={<Suspense fallback={false}><PageHarga /></Suspense>} />
              <Route path="create" element={<Suspense fallback={false}><FormHarga /></Suspense>} />
              <Route path="edit/:kodeOrigin/:kodeDestination" element={<Suspense fallback={false}><FormHarga /></Suspense>} />
            </Route>
          </Route>
          <Route path="pickup-order" element={<Outlet />}>
            <Route index element={<Suspense fallback={false}><PagePickupOrder /></Suspense>} />
          </Route>
          <Route path="resi/*" element={<Suspense fallback={false}><ResiPage /></Suspense>} />
          <Route path="manifest/*" element={<Suspense fallback={false}><ManifestPage /></Suspense>} />
          <Route path="laporan/*" element={<Suspense fallback={false}><LaporanRoute /></Suspense>} />
          <Route path="pembiayaan/*" element={<Suspense fallback={false}><PembiayaanPage /></Suspense>} />
          <Route path="vendor" element={<Outlet />}>
            <Route index element={<Suspense fallback={false}><VendorPage /></Suspense>} />
            <Route path="create" element={<Suspense fallback={false}><VendorForm /></Suspense>} />
            <Route path="edit/:id" element={<Suspense fallback={false}><VendorForm /></Suspense>} />
            <Route path="detail/:id" element={<Suspense fallback={false}><VendorDetail /></Suspense>} />
          </Route>
          <Route path="kendaraan" element={<Outlet />}>
            <Route index element={<Suspense fallback={false}><KendaraanPage /></Suspense>} />
            <Route path="create" element={<Suspense fallback={false}><KendaraanForm /></Suspense>} />
            <Route path="edit/:id" element={<Suspense fallback={false}><KendaraanForm /></Suspense>} />
          </Route>
          <Route path="broadcast" element={<Suspense fallback={false}><BroadcastPage /></Suspense>} />
          <Route path="website/*" element={<Suspense fallback={false}><WebsiteRoute /></Suspense>} />

          <Route path="user" element={<Suspense fallback={false}><ProfilePage /></Suspense>} />
          <Route path="setting" element={<Suspense fallback={false}><SettingPage /></Suspense>} />

          <Route path="*" element={<Navigate to="/error-404" />} />
        </Route>
      </Routes>
    </Provider>
  );
}
