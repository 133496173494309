import React from 'react';
import { Container } from 'react-bootstrap';
import { useApp } from './Provider';

export default function Footer() {
  const {setting} = useApp();

  return (
    <div className="main-footer bg-transparent text-black">
      <Container fluid className="py-2 d-block d-md-flex align-items-center justify-content-between">
        <p className="my-0">COPYRIGHT © {new Date().getFullYear()} <b>{setting?.app_name}</b>, All rights Reserved.</p>
        <p className="my-0 text-end">Made with <span className="text-danger">❤</span></p>
      </Container>
    </div>
  )
}
