import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Brand from '../components/Brand';

export default function Error404() {
  return (
    <div className="min-vh-100 bg-white">
      <Brand />

      <Container fluid className="py-5">
        <Row className="justify-content-center">
          <Col md={5}>
            <div className="pt-5 pt-md-0 text-center">
              <h2>Page Not Found!</h2>
              <p>Oops! 😖 The requested URL was not found on this server.</p>
              <Link to="/main/dashboard" className="btn btn-primary">Back to home</Link>

              <div className="mt-4">
                <Image src="/storage/error-404.svg" className="img-fluid" />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
