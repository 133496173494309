import React from 'react';
import { Container } from 'react-bootstrap';

export default function Content({ children }) {
  return (
    <div className="main-content">
      <Container fluid>
        {children}
      </Container>
    </div>
  )
}
