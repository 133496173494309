import React from 'react';
import { Link } from 'react-router-dom';
import Logo from './Logo';
import { useApp } from './Provider';

export default function Brand() {
  const {setting} = useApp();

  return (
    <Link to="/main/dashboard" className="position-absolute d-flex align-items-center"
      style={{
        top: 25,
        left: 25,
        textDecoration: 'none'
      }}
    >
      <Logo height={50} />
      <h4 className="ms-2 my-0 fw-bold">{setting?.app_name}</h4>
    </Link>
  )
}
