import React, { useEffect, useState } from 'react';
import ReactApexcharts from 'react-apexcharts';
import api from '../../services/api';
import axios from 'axios';

export default function ChartFinance() {
  const sourceToken = axios.CancelToken.source();

  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});

  const fetchData = async () => {
    const { data } = await api.get(`/resi/rekap-bulanan`, {
      params: {
        tahun: new Date().getFullYear()
      },
      cancelToken: sourceToken.token
    });

    const categories = [];
    const values = [];

    data.forEach(item => {
      categories.push(item.short);
      values.push(item.persentase);
    });

    setSeries([
      {
        name: 'Persentase',
        data: values
      }
    ]);

    setOptions({
      chart: {
        height: 350,
        type: 'line',
      },
      stroke: {
        width: 7,
        curve: 'smooth'
      },
      xaxis: {
        type: 'text',
        categories: categories,
      },
      title: {
        text: 'Grafik Pengiriman',
        align: 'left',
        style: {
          fontSize: "16px",
          color: '#666'
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          gradientToColors: [ '#FDD835'],
          shadeIntensity: 1,
          type: 'horizontal',
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100, 100, 100]
        },
      },
      markers: {
        size: 4,
        colors: ["#FFA41B"],
        strokeColors: "#fff",
        strokeWidth: 2,
        hover: {
          size: 7,
        }
      },
      yaxis: {
        min: 0,
        title: {
          text: 'Persentase',
        },
        labels: {
          show: true,
          formatter: function (val) {
            return new Intl.NumberFormat('id-ID', {minimumFractionDigits: 0, maximumFractionDigits: 0}).format(val) + "%";
          }
        }
      }
    });
  }

  useEffect(() => {
    fetchData();

    return () => {
      sourceToken.cancel('Aborting current request.');
    }
  }, []);

  return (
    <div className="card mb-3">
      <div className="card-body">
        <ReactApexcharts
          options={options}
          series={series}
          type="line"
          height={400}
        />
      </div>
    </div>
  )
}
