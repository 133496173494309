import React, { Fragment } from "react";
import ChartFinance from "../components/stats/ChartFinance";
// import StatsPickupOrder from "../components/stats/StatsPickupOrder";
// import StatsResi from "../components/stats/StatsResi";
import CardWablas from "../components/CardWablas";
import StatsKendaraan from "../components/stats/StatsKendaraan";
import StatsResiStatus from "../components/stats/StatsResiStatus";

export default function Dashboard() {
  return (
    <Fragment>
      <div className="row">
        <StatsResiStatus />

        <div className="col-sm-3">
          <StatsKendaraan />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-8">
          <ChartFinance />
        </div>

        <div className="col-sm-4">
          <CardWablas />
        </div>
      </div>
    </Fragment>
  );
}
