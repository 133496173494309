import React, { Fragment, useEffect, useState } from "react";
import {
  FiBox,
  FiCircle,
  FiDisc,
  FiHome,
  FiX,
  FiUmbrella,
  FiTruck,
  FiPrinter,
  FiFileText,
} from "react-icons/fi";
import { BsBroadcastPin } from "react-icons/bs";
import { CgWebsite } from "react-icons/cg";
import { FaMotorcycle, FaCar, FaMoneyBillWave } from "react-icons/fa";
import { NavLink, useLocation } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import Logo from "./Logo";
import { useApp } from "./Provider";
import { Collapse } from "react-bootstrap";
import classNames from "classnames";

const rootURL = "/main";
const sitemap = [
  {
    elementId: "dashboard",
    label: "Dashboard",
    url: `${rootURL}/dashboard`,
    isRoot: true,
    icon: <FiHome />,
    level: 1,
  },
  {
    elementId: "master",
    label: "Master Data",
    url: `${rootURL}/master`,
    isRoot: true,
    icon: <FiBox />,
    level: 1,
    children: [
      {
        elementId: "masterWilayah",
        label: "Kode Wilayah",
        url: `${rootURL}/master/wilayah`,
        level: 2,
        children: [
          {
            elementId: "masterWilayahKota",
            label: "Kota / Kabupaten",
            url: `${rootURL}/master/wilayah/kota`,
            level: 3,
          },
          {
            elementId: "masterWilayahKecamatan",
            label: "Kecamatan",
            url: `${rootURL}/master/wilayah/kecamatan`,
            level: 3,
          },
          {
            elementId: "masterWilayahKelurahan",
            label: "Kelurahan / Desa",
            url: `${rootURL}/master/wilayah/kelurahan`,
            level: 3,
          },
        ],
      },
      {
        elementId: "masterKantor",
        label: "Kantor / Cabang",
        url: `${rootURL}/master/kantor`,
        level: 2,
      },
      {
        elementId: "masterUsers",
        label: "Manage Users",
        url: `${rootURL}/master/users`,
        level: 2,
      },
      {
        elementId: "masterPengirim",
        label: "Manage Pengirim",
        url: `${rootURL}/master/pengirim`,
        level: 2,
      },
      {
        elementId: "masterPenerima",
        label: "Manage Penerima",
        url: `${rootURL}/master/penerima`,
        level: 2,
      },
      {
        elementId: "masterLayanan",
        label: "Jenis Layanan",
        url: `${rootURL}/master/layanan`,
        level: 2,
      },
      {
        elementId: "masterJenisBarang",
        label: "Jenis Barang",
        url: `${rootURL}/master/jenis-barang`,
        level: 2,
      },
      {
        elementId: "masterJenisPembayaran",
        label: "Jenis Pembayaran",
        url: `${rootURL}/master/jenis-pembayaran`,
        level: 2,
      },
      {
        elementId: "masterJenisKiriman",
        label: "Jenis Kiriman",
        url: `${rootURL}/master/jenis-kiriman`,
        level: 2,
      },
      {
        elementId: "masterJenisLevel",
        label: "Jenis Level Pengiriman",
        url: `${rootURL}/master/jenis-level`,
        level: 2,
      },
      {
        elementId: "masterHargaPengiriman",
        label: "Harga Pengiriman",
        url: `${rootURL}/master/harga-pengiriman`,
        level: 2,
      },
    ],
  },
  {
    elementId: "pickupOrder",
    label: "Pickup Order",
    url: `${rootURL}/pickup-order`,
    isRoot: true,
    icon: <FaMotorcycle />,
    level: 1,
  },
  {
    elementId: "resi",
    label: "Resi",
    url: `${rootURL}/resi`,
    isRoot: true,
    icon: <FiFileText />,
    level: 1,
  },
  {
    elementId: "manifest",
    label: "Manifest",
    url: `${rootURL}/manifest`,
    isRoot: true,
    icon: <FiTruck />,
    level: 1,
    children: [
      {
        elementId: "manifestOrigin",
        label: "Manifest Origin",
        url: `${rootURL}/manifest/origin`,
        level: 2,
      },
      {
        elementId: "manifestValidasi",
        label: "Validasi Manifest",
        url: `${rootURL}/manifest/validasi`,
        level: 2,
      },
      {
        elementId: "manifestKurir",
        label: "Manifest Kurir",
        url: `${rootURL}/manifest/hub`,
        level: 2,
      },
    ],
  },
  {
    elementId: "laporan",
    label: "Laporan",
    url: `${rootURL}/laporan`,
    isRoot: true,
    icon: <FiPrinter />,
    level: 1,
    children: [
      {
        elementId: "laporanManifest",
        label: "Rekap Resi",
        url: `${rootURL}/laporan/manifest`,
        level: 2,
      },
      {
        elementId: "laporanKinerjaKurir",
        label: "Kinerja Kurir",
        url: `${rootURL}/laporan/kinerja-kurir`,
        level: 2,
      },
      {
        elementId: "laporanKeuangan",
        label: "Pembiayaan (Keuangan)",
        url: `${rootURL}/laporan/keuangan`,
        level: 2,
      },
    ],
  },
];

const utilities = [
  {
    elementId: "pembiayaan",
    label: "Pembiayaan (Keuangan)",
    url: `${rootURL}/pembiayaan`,
    isRoot: true,
    icon: <FaMoneyBillWave />,
    level: 1,
  },
  {
    elementId: "vendor",
    label: "Subcon / Vendor",
    url: `${rootURL}/vendor`,
    isRoot: true,
    icon: <FiUmbrella />,
    level: 1,
  },
  {
    elementId: "kendaraan",
    label: "Kendaraan",
    url: `${rootURL}/kendaraan`,
    isRoot: true,
    icon: <FaCar />,
    level: 1,
  },
  {
    elementId: "broadcast",
    label: "Broadcast",
    url: `${rootURL}/broadcast`,
    isRoot: true,
    icon: <BsBroadcastPin />,
    level: 1,
  },
];

export default function SidebarNew() {
  const { modeIcon, toggleModeIcon, mobileView } = useApp();

  return (
    <div className="main-sidebar">
      <div className="header">
        <div className="app-brand">
          <NavLink
            to="/main/dashboard"
            onClick={() => mobileView && toggleModeIcon()}
          >
            <Logo className="brand-logo" />
            <div className="brand-label">{"Office"}</div>
          </NavLink>
        </div>
        <div className="icon-toggler" onClick={toggleModeIcon}>
          {modeIcon ? <FiCircle /> : mobileView ? <FiX /> : <FiDisc />}
        </div>
      </div>

      <div className="body">
        <PerfectScrollbar>
          <ul className="nav flex-column">
            {/* render root sitemap */}
            {sitemap.map((site) => (
              <li key={site.elementId} className="nav-item">
                {site.children ? (
                  <TreeBuilder data={site} />
                ) : (
                  <NavLink
                    to={site.url}
                    className="nav-link"
                    onClick={() => mobileView && toggleModeIcon()}
                  >
                    <div className="nav-icon">{site.icon}</div>
                    <div className="nav-label">{site.label}</div>
                  </NavLink>
                )}
              </li>
            ))}
          </ul>

          <div className="px-3">
            <hr />
          </div>

          <ul className="nav flex-column mb-5">
            {utilities.map((site) => (
              <li key={site.elementId} className="nav-item">
                {site.children ? (
                  <TreeBuilder data={site} />
                ) : (
                  <NavLink
                    to={site.url}
                    className="nav-link"
                    onClick={() => mobileView && toggleModeIcon()}
                  >
                    <div className="nav-icon">{site.icon}</div>
                    <div className="nav-label">{site.label}</div>
                  </NavLink>
                )}
              </li>
            ))}
            <li className="nav-item">
              <a
                href="https://raxcargo.prismic.io"
                target="_blank"
                rel="noreferrer"
                className="nav-link"
              >
                <div className="nav-icon">
                  <CgWebsite />
                </div>
                <div className="nav-label">Website</div>
              </a>
            </li>
          </ul>
        </PerfectScrollbar>
      </div>
    </div>
  );
}

const TreeBuilder = ({ data }) => {
  const location = useLocation();
  const { mobileView, toggleModeIcon } = useApp();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (location.pathname.startsWith(data.url)) {
      setOpen(true);
    }
  }, [location.pathname, data.url]);

  return (
    <Fragment>
      <div
        className="nav-link has-sub"
        onClick={() => setOpen(!open)}
        aria-expanded={open}
      >
        {data.icon && <div className="nav-icon">{data.icon}</div>}
        <div className="nav-label">{data.label}</div>
      </div>
      <Collapse in={open}>
        <ul
          id={`level-${data.level}`}
          className={classNames("nav flex-column", {
            "nav-children": data.level === 1,
            "nav-children-sub": data.level >= 2,
          })}
        >
          {data.children?.map((site) => (
            <li key={site.elementId} className="nav-item">
              {site.children ? (
                <TreeBuilder data={site} />
              ) : (
                <NavLink
                  to={site.url}
                  className="nav-link"
                  onClick={() => mobileView && toggleModeIcon()}
                >
                  {site.label}
                </NavLink>
              )}
            </li>
          ))}
        </ul>
      </Collapse>
    </Fragment>
  );
};
