import React, { createContext, Fragment, useContext, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import api, { API_KEY } from '../services/api';

const AppContext = createContext();

export default function Provider({ children }) {
  const [modeIcon, setModeIcon] = useState(false);
  const [mobileView, setMobileView] = useState(window.innerWidth <= 768 ? true : false);
  const [loading, setLoading] = useState({
    setting: true,
    user: false,
    refer: false,
  });
  const [setting, setSetting] = useState(null);
  const [user, setUser] = useState(null);
  const [refer, setRefer] = useState({});

  const toggleModeIcon = () => {
    setModeIcon(!modeIcon);
    document.body.classList.toggle('hide-scrollbar', mobileView && modeIcon);
  };

  const handleModeIcon = () => setModeIcon(true);

  const signin = (callback) => {
    return fakeAuthProvider.signin(() => {
      fetchUser();
      callback();
    });
  };

  const signout = (callback) => {
    return fakeAuthProvider.signout(async () => {
      await api.post('/auth/logout');
      setUser(null);
      localStorage.removeItem(API_KEY);
      callback();
    });
  };

  const fetchSetting = async () => {
    setLoading(prevState => ({...prevState, setting: true}));
    const { data } = await api.get('/setting');
    setSetting(data);
    setLoading(prevState => ({...prevState, setting: false}));
  }

  const fetchUser = async () => {
    setLoading(prevState => ({...prevState, user: true}));
    const { data } = await api.get('/auth/me');
    setUser(data);
    setLoading(prevState => ({...prevState, user: false}));
  }

  const fetchRefer = async () => {
    setLoading(prevState => ({...prevState, refer: true}));
    try {
      const { data } = await api.get(`/refer`);
      setRefer(data);

      setLoading(prevState => ({...prevState, refer: false}));
    } catch (error) {
      setLoading(prevState => ({...prevState, refer: false}));
      console.error(error);
    }
  }

  useEffect(() => {
    fetchSetting();

    const token = JSON.parse(localStorage.getItem(API_KEY));
    if (!!token) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
      fetchUser();
      fetchRefer();
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setMobileView(window.innerWidth <= 768 ? true : false);
    }
    window.addEventListener('resize', handleResize);
    setModeIcon(mobileView ? true : false);    

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [mobileView]);

  const value = {
    modeIcon,
    toggleModeIcon,
    handleModeIcon,
    mobileView,
    setting,
    fetchSetting,
    user,
    fetchUser,
    refer,
    signin,
    signout
  };

  if (loading.setting) {
    return (
      <Fragment>
        <p>Configuration...</p>
      </Fragment>
    );
  }

  if (loading.user) {
    return (
      <Fragment>
        <p>Fetch user profile...</p>
      </Fragment>
    );
  }

  if (loading.user) {
    return (
      <Fragment>
        <p>Fetch user profile...</p>
      </Fragment>
    );
  }

  return (
    <AppContext.Provider value={value}>
      <ToastContainer />
      {children}
    </AppContext.Provider>
  )
}

export const useApp = () => useContext(AppContext);

const fakeAuthProvider = {
  isAuthenticated: false,
  signin(callback) {
    fakeAuthProvider.isAuthenticated = true;
    setTimeout(callback, 100); // fake async
  },
  signout(callback) {
    fakeAuthProvider.isAuthenticated = false;
    setTimeout(callback, 100);
  },
};
