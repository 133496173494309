import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import api from "../../services/api";
import { FiClock, FiCheckCircle } from "react-icons/fi";
import { VscRefresh } from "react-icons/vsc";
import { Button } from "react-bootstrap";

export default function StatsResi() {
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState({ progress: 0, sukses: 0 });

  const fetchData = async () => {
    const sourceToken = axios.CancelToken.source();

    try {
      setLoading(true);
      const { data } = await api.get(`/resi/stats`, {
        params: {
          status: true,
        },
        cancelToken: sourceToken.token,
      });
      setCount(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const sourceToken = axios.CancelToken.source();

    fetchData();

    return () => {
      sourceToken.cancel("Aborting current request.");
    };
  }, []);

  return (
    <Fragment>
      <div className="col-sm-3">
        <div className="card text-bg-info mb-3">
          <div className="card-body">
            <div className="mb-3 d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <FiClock className="me-2" />
                <span>{loading ? "Loading..." : "Progress"}</span>
              </div>
              <div>
                <Button
                  variant="outline-dark"
                  className="border-0"
                  size="sm"
                  onClick={fetchData}
                >
                  <VscRefresh />
                </Button>
              </div>
            </div>
            <div className="text-center">
              <p className="display-6 mb-0">{count.progress}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-3">
        <div className="card text-bg-primary mb-3">
          <div className="card-body">
            <div className="mb-3 d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <FiCheckCircle className="me-2" />
                <span>{loading ? "Loading..." : "Sukses"}</span>
              </div>
              <div>
                <Button
                  variant="outline-dark"
                  className="border-0"
                  size="sm"
                  onClick={fetchData}
                >
                  <VscRefresh />
                </Button>
              </div>
            </div>
            <div className="text-center">
              <p className="display-6 mb-0">{count.sukses}</p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
