import React from 'react';
import Navbar from './Navbar';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import Content from './Content';
import { useApp } from './Provider';
import classNames from 'classnames';
import Sidebar from './Sidebar';
import Footer from './Footer';

export default function Layout() {
  const { modeIcon } = useApp();
  const { user } = useApp();
  const location = useLocation();

  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <main className={classNames('d-flex flex-column min-vh-100', {'mode-icon': modeIcon})}>
      <Navbar />
      <Sidebar />

      <Content>
        <Outlet />
      </Content>

      <Footer />
    </main>
  )
}
