import { useFormik } from 'formik';
import React from 'react';
import { useNavigate, useLocation, Navigate, Link } from 'react-router-dom';
import { useApp } from '../../components/Provider';
import * as Yup from 'yup';
import { Form, Button, Image } from 'react-bootstrap';
import { API_KEY } from '../../services/api';
import Brand from '../../components/Brand';

export default function Register() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, signin } = useApp();

  const from = location.state?.from?.pathname || "/";

  const formik = useFormik({
    initialValues: { username: '', email: '', password: '', agreement: false },
    validationSchema: Yup.object().shape({
      username: Yup.string().required().label('Username'),
      email: Yup.string().email().required().label('Email'),
      password: Yup.string().required().label('Password'),
      agreement: Yup.boolean().oneOf([true]).label('Agreement'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      signin(() => {
        localStorage.setItem(API_KEY, JSON.stringify(values));
        navigate(from, { replace: true });
      });
      setSubmitting(false);
    }
  });

  if (!!user) {
    return <Navigate to={from} replace />;
  }

  return (
    <div className="min-vh-100">
      <Brand />

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-8 min-vh-100 p-3 p-lg-5 d-none d-md-flex">
            <div className="w-100 d-lg-flex align-items-center justify-content-center px-5">
              <Image src="/storage/register-bg.svg" alt="Register Background" className="img-fluid" />
            </div>
          </div>
          <div className="col-sm-4 min-vh-100 px-4 px-sm-3 py-5 p-lg-5 bg-white">
            <header className="mb-4 pt-5 pt-md-0 d-flex flex-column text-start">
              <h4>Adventure starts here 🚀</h4>
              <p className="text-muted">Make your app management easy and fun!</p>
            </header>

            <Form noValidate onSubmit={formik.handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  name="username"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.username && formik.errors.username}
                  placeholder="Username"
                  className="bg-white"
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.username}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.email && formik.errors.email}
                  placeholder="Email"
                  className="bg-white"
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.email}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.password && formik.errors.password}
                  placeholder="Password"
                  className="bg-white"
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.password}
                </Form.Control.Feedback>
              </Form.Group>

              <div className="mb-3">
                <Form.Check
                  type="checkbox"
                  name="agreement"
                  label={<div>I agree to <u>privacy policy &amp; terms</u></div>}
                  onChange={e => formik.setFieldValue('agreement', e.target.checked)}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.agreement && formik.errors.agreement}
                />
              </div>

              <div className="d-grid gap-2">
                <Button type="submit" variant="primary" disabled={!formik.isValid}>Sign up</Button>
              </div>
            </Form>

            <br />

            <div className="d-flex align-items-center">
              <span className="me-2">Have an account?</span>
              <Link to="/login">Sign in</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
